import React, { Component, useEffect, useRef, useState } from 'react';
import { EditorState, convertToRaw, ContentState, convertFromHTML, RichUtils, DefaultDraftBlockRenderMap } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import SunEditor from 'suneditor-react';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import styles from './CreateTemplate.module.css';
import parse from 'html-react-parser';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { Button, notification, Tabs } from 'antd';
import ReactToPrint from 'react-to-print';
import previewIcon from '../../../assets/images/icon/file.png';
import previewHoverIcon from '../../../assets/images/icon/file-hover.png';
import pdfIcon from '../../../assets/images/icon/pdf-file.png';
import pdfHoverIcon from '../../../assets/images/icon/pdf-file-hover.png';
import saveIcon from '../../../assets/images/icon/floppy-disk.png';
import saveHoverIcon from '../../../assets/images/icon/floppy-disk-hover.png';
import axios from 'axios';
import { createPortal } from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { getTemplateHtmlByTemplateId, saveHtmlTemplate } from '../../../services/ReportTemplateService';
import config from '../../../constants/config';
import TableUtils from 'draft-js-table';
import Immutable from 'immutable';
import { customBlockRenderFunc } from '../../../components/container/DraftJsTableRenderer';
import SunEditorConfigs from '../../../constants/SunEditorConfig';
import { useAppDispatch } from '../../../app/hooks';
import { setBack2PageAction, setPagenameAction } from '../../../store/page/action';

const CreateTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
    const { id } = useParams();
    const pdfCanvas = useRef();
    // const [pdfCanvasState, setPdfCanvasState] = useState();
    const [currentTemplateModel, setCurrentTemplateModel] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [currentHtml, setCurrentHtml] = useState("");
    const [currentHtmlElement, setCurrentHtmlElement] = useState();
    // const [editHtml, setEditHtml] = useState("");
    const [isPreview, setIsPreview] = useState(false);
    const [currentTop, setCurrentTop] = useState(0);
    const [currentToolTop, setCurrentToolTop] = useState(50);
    const [isParseError, setIsParseError] = useState(false);

    const [previewIconState, setPreviewIconState] = useState(previewIcon);
    const [pdfIconState, setPdfIconState] = useState(pdfIcon);
    const [export2PdfIconState, setExport2PdfIconState] = useState(pdfIcon);
    const [saveIconState, setSaveIconState] = useState(saveIcon);
    const [isEditFromEditor, setIsEditFromEditor] = useState(false);

    useEffect(() => {
      dispatch(setPagenameAction("Template Editor"));
      dispatch(setBack2PageAction("/app"));
      loadReportTemplate();
    }, [])
    useEffect(() => {
      // console.log("currentTop: ", currentTop);
      let newToolTop = 96 - currentTop;
      setCurrentToolTop(newToolTop < 0 ? 0 : newToolTop);
    }, [currentTop])
    useEffect(() => {
      // setEditHtml(currentHtml);
      pdfCanvas.current.innerHTML = currentHtml;
    }, [currentHtml])
    useEffect(() => {
      if(currentTemplateModel){
        if(currentTemplateModel.templateHtml && currentTemplateModel.templateHtml.length > 0){
          let currHtml = currentTemplateModel.templateHtml[0].htmlText == null ? "" : currentTemplateModel.templateHtml[0].htmlText;
          setCurrentHtml(currHtml)
          setHtmlToEditorState(currHtml);
        }
      }
    }, [currentTemplateModel])

    const setHtmlToEditorState = (htmlStr) => {
      const contentBlock = htmlToDraft(htmlStr);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      setEditorState(EditorState.createWithContent(contentState));
    }
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setCurrentHtml(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };
    const export2Pdf = () => {
      window.open(`${config.urlRoot}/report/readPDF/html2Pdf/${id}.pdf`, "_blank")
    }
    const saveTemplate = async (content = null) => {
      let params = {
        templateId: id,
        htmlStr: content ? content : currentHtml
      }
      let res = await saveHtmlTemplate(params);
      console.log("saveTemplate: ", res);
      if(res.status == 200){
        notification.success({message: 'บันทึกข้อมูลสำเร็จ'})
      }else{
        notification.error({message: 'บันทึกข้อมูลไม่สำเร็จ'})
      }
    }
    const loadReportTemplate = async () => {
      let res = await getTemplateHtmlByTemplateId(id);
      console.log("loadReportTemplate: ", res);
      if(res){
        setCurrentTemplateModel(res);
      }
      // if(res && res.dat){
      //   res.data
      // }
    }
    const printDocument = () => {
        const input = document.getElementById('pdfPreview');
        if(input){
          html2canvas(input)
            .then((canvas) => {
              const imgData = canvas.toDataURL('image/png');
              const pdf = new jsPDF();
              pdf.addImage(imgData, 'JPEG', 0, 0);
              // pdf.output('dataurlnewwindow');
              pdf.save("download.pdf");
            });
        }
    }
    const renderSunEditor = () => {
      return (
        <div className={styles['editor-container']}>
          <div
            style={{
              backgroundColor: '#ffffff',
              width: '210mm',
              // height: '82vh',
              marginLeft: 'auto',
              marginRight: 'auto',
              // padding: '10mm',
            }}
          >
            <SunEditor 
              setContents={currentHtml} 
              setOptions={{buttonList: SunEditorConfigs.buttonList, font: SunEditorConfigs.font, lineHeights: SunEditorConfigs.lineHeights, imageGalleryUrl: SunEditorConfigs.imageGalleryUrl}} 
              onChange={(content) => {setCurrentHtml(content)}}
              width={"210mm"}
              height={"75vh"}
              onSave={(content) => {saveTemplate(content)}}
            />
          </div>
        </div>
      )
    }
    const renderEditor = () => {
      // const blockRenderMap = NestedUtils.DefaultBlockRenderMap
      //     .merge(TableUtils.DefaultBlockRenderMap);
      // console.log("TableUtils.DefaultBlockRenderMap: ", TableUtils.DefaultBlockRenderMap);
      // const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(Immutable.Map(TableUtils.DefaultBlockRenderMap));
      // console.log("extendedBlockRenderMap: ", extendedBlockRenderMap);
      return (
        <div className={styles['editor-container']}>
          <div
            style={{
              backgroundColor: '#ffffff',
              width: '210mm',
              // height: '297mm',
              marginLeft: 'auto',
              marginRight: 'auto',
              // padding: '10mm',
            }}
          >
            <Editor
              editorState={editorState}
              wrapperClassName={"wrapper-class"}
              editorClassName={styles["editor"]}
              toolbarClassName={"toolbar-class"}
              // blockRenderMap={blockRenderMap}
              customBlockRenderFunc={customBlockRenderFunc}
              onEditorStateChange={onEditorStateChange}
              toolbarStyle={{
                width: '210mm',
                position: 'fixed',
                top: currentToolTop,
                // marginTop: -50,
                zIndex: 90,
              }}
              editorStyle={{
                backgroundColor: '#ffffff',
                width: '210mm',
                height: '297mm',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 50,
                padding: '10mm',
              }}
              wrapperStyle={{
                backgroundColor: '#ffffff',
                width: '210mm',
                // height: '297mm',
                // marginLeft: 'auto',
                // marginRight: 'auto',
                // padding: '10mm',
              }}
            />
          </div>
        </div>
      )
    }
    const renderTextarea = () => {
      return (
        <div className={styles['text-area-container']} style={{flexDirection: 'column'}}>
          <textarea
            // disabled
            onChange={(e) => {
              setCurrentHtml(e.target.value);
              setHtmlToEditorState(e.target.value);
            }}
            value={currentHtml}
            style={{
              backgroundColor: isParseError ? '#ff0000' : '#ffffff',
              width: '210mm',
              minHeight: 300,
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: '10mm',
              overflowY: 'auto'
            }}
            height={100}
          />
          {/* <Button style={{marginTop: 10}} onClick={() => {setCurrentHtml(editHtml)}}>แก้ไข HTML</Button> */}
        </div>
      )
    }
    return (
        <div className={styles['main-container']} onScroll={(evt) => {setCurrentTop(evt.currentTarget.scrollTop)}}>
          {/* <Tabs>
            <Tabs.TabPane tab="SunEditor" key={"0"}>
              {renderSunEditor()}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Editor" key={"1"}>
              {renderEditor()}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Textarea" key={"2"}>
              {renderTextarea()}
            </Tabs.TabPane>
          </Tabs> */}
          {renderSunEditor()}
          <div className={styles['preview']} style={{display: isPreview ? 'flex' : 'none'}}>
            <div className={styles['closePreview']} onClick={() => {setIsPreview(false)}}>Close</div>
            <div ref={pdfCanvas} id="pdfPreview" className="mt4"
                style={{
                  backgroundColor: '#ffffff',
                  width: '210mm',
                  height: '297mm',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  padding: '10mm',
                  // scale: 2,
                  overflowY: 'auto'
                }}
              >

              </div>
          </div>
          <div className={styles['managementContainer']}>
            {/* <div 
              className={styles['manageButton']} 
              onClick={() => {setIsPreview(true)}} 
              onMouseOver={() => {setPreviewIconState(previewHoverIcon)}}
              onMouseOut={() => {setPreviewIconState(previewIcon)}}
            >
              <img src={previewIconState} style={{width: 24, height: 24}}></img>
              Preview
            </div> */}
            {/* <ReactToPrint
              className={styles['manageButton']}
              content={() => pdfCanvas.current}
              trigger={() => 
                <div 
                  className={styles['manageButton']} 
                  onMouseOver={() => {setPdfIconState(pdfHoverIcon)}} 
                  onMouseOut={() => {setPdfIconState(pdfIcon)}}
                >
                  <img src={pdfIconState} style={{width: 24, height: 24}}></img>
                  Print to PDF
                </div>
              }
            /> */}
            <div 
              className={styles['manageButton']} 
              onClick={() => {export2Pdf()}}
              onMouseOver={() => {setExport2PdfIconState(pdfHoverIcon)}}
              onMouseOut={() => {setExport2PdfIconState(pdfIcon)}}
            >
              <img src={export2PdfIconState} style={{width: 24, height: 24}}></img>
              <span style={{textAlign: 'center'}}>Export to PDF</span>
            </div>
            {/* <div 
              className={styles['manageButton']} 
              onClick={() => {saveTemplate()}}
              onMouseOver={() => {setSaveIconState(saveHoverIcon)}}
              onMouseOut={() => {setSaveIconState(saveIcon)}}
            >
              <img src={saveIconState} style={{width: 24, height: 24}}></img>
              บันทึก
            </div> */}
          </div>
        </div>
    );
}

export default CreateTemplate;