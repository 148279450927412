import React, { useCallback, useEffect, useRef, useState } from "react";
import { Steps, Table, Modal, notification } from 'antd';
import { UploadOutlined, FileAddOutlined, DeleteOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Tooltip, Upload } from 'antd';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import classes from "./Panel.module.css";
import ResourceFileResponse from '../../models/ResourceFileResponse';
import axios from 'axios';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { render } from "@testing-library/react";
import Resource from "../container/Resource";
import configs from "../../constants/config";
import ResponseData from "../../models/ResponseData";
import { ReportTemplateDTO, TemplateFiles } from "../../views/report/UploadTemplateModel";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

interface IProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  uploadPath?: string;
//   acceptExtension?: string;
  uploadProps?: UploadProps;
  reportTemplateId?: string | null | undefined;
  filename?: string;
  fileList?: TemplateFiles[];
  preview?: (uploadStatus: string, pdfBase64: string) => void;
  clearReport?: () => void;
  onUploaded: (uploadStatus: string, resourceFile: any, file: UploadFile) => void;
}

interface IUploadedTracker{
    uploadedCounting: number,
    currentUploading: any[]
}

const type = 'DragableUploadList';

interface DragableUploadListItemProps {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  file: UploadFile;
  fileList: UploadFile[];
  moveRow: (dragIndex: any, hoverIndex: any) => void;
}

const DragableUploadListItem = ({
  originNode,
  moveRow,
  file,
  fileList,
}: DragableUploadListItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>;
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move' }}
    >
      {file.status === 'error' ? errorNode : originNode}
    </div>
  );
};


const ReportUpload: React.FC<IProps> = (props) => {
    // const extall = "jpg ,jpeg ,gif ,png, pdf";
    const defaulUploadPath = `${configs.urlRoot}/report/jrxmlUpload`;
    // const defaulUploadPath = `${configs.urlRoot}/report/readReportParameter`;
    // const defaulUploadPath = `${configs.urlRoot}/report/readReportListParameter`;
    
    const [extall, setExtall] = useState<string | null | undefined>("jrxml");
    const [uploadPath, setUploadPath] = useState<string>(defaulUploadPath);
    const [fileList, setFileList] = useState<Array<any>>([]);
    const [children, setChildren] = useState<React.ReactNode>();
    const [reportTemplateId, setReportTemplateId] = useState<string | null | undefined>();
    const [filename, setFilename] = useState<string | null>();
    const [timeoutConstant, setTimeoutConstant] = useState<any>();
    const [generatedList, setGeneratedList] = useState<Array<ReportTemplateDTO>>([]);
    const [isUploaded, setIsUploaded] = useState<boolean>(false);

    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>("");
    const [previewTitle, setPreviewTitle] = useState<string>("");

    const [fileUpload, setFileUpload] = useState<string>();
    const [progress, setProgress] = useState<number>();

    // UseEffect
    // useEffect(() => {
    //     setExtall(props.acceptExtension);
    // }, [props.acceptExtension]);

    useEffect(() => {
        if(props.uploadPath){
            setUploadPath(props.uploadPath);
        }else{
            setUploadPath(defaulUploadPath);
        }
    }, [props.uploadPath]);

    useEffect(() => {
        if(props.children){
            setChildren(props.children);
        }else{
            setChildren(null);
        }
    }, [props.children]);

    useEffect(() => {
        setReportTemplateId(props.reportTemplateId);
    }, [props.reportTemplateId])

    useEffect(() => {
        if(props.fileList){
            // console.log("props.fileList update", props.fileList);
            let newFileList: any[] = [];
            for(let i = 0; i < props.fileList.length; i++){
                let template = props.fileList[i];
                let file = {
                    uid: template.id,
                    name: template.filename,
                    status: 'done',
                    // url: 'http://www.baidu.com/yyy.png',
                };
                newFileList.push(file);
            }
            setFileList(newFileList)
        }
    }, [props.fileList])

    useEffect(() => {
        if(reportTemplateId == null){
            setFileList([]);
        }else{
            setFileList([
                // {
                //     uid: reportTemplateId,
                //     status: 'done',
                //     name: filename,
                //     url: `${configs.getResourceUrl(reportTemplateId)}`
                // }
            ])
        }
    }, [reportTemplateId])

    useEffect(() => {
        setFilename(props.filename);
    }, [props.filename])

    const allUploadedCallback = async () => {
        console.log("All uploaded");
        let newFileList = [...fileList];
        sortNewList(newFileList);
        setTracker({
            uploadedCounting: 0,
            currentUploading: []
        })

        // uploadCounting = 0;
        // currentUploadFileList = [];
    }
    const sortNewList = async (newFileList: any[]) => {
        let url = `${configs.urlRoot}/report/sortFileByFrontList`
        let data = []
        // setFileList(newFileList);
        for(let i = 0; i < newFileList.length; i++){
            data.push(newFileList[i].uid);
        }
        console.log("send to update: ", data);
        let res = await axios.post(url, {templateFileIdList: data});
        if(res.status == 200){
            let responseData: ResponseData = res.data;
            if(responseData.success){
                console.log(responseData.data);
            }
        }
    }
    // Upload properties setup
    const [tracker, setTracker] = useState<IUploadedTracker>({
        uploadedCounting: 0,
        currentUploading: []
    });
    const uploadProps = {
        name: "image",
        // action: (file: RcFile) => {
        //     console.log("action file", file);
        //     return 'http://localhost:8080/file/upload'
        // },
        customRequest: async (options: any) => {
            console.log("Custom Request");
            const { onSuccess, onError, file, onProgress } = options;
            const formData = new FormData();
            // console.log("file: ", file);
            fileList.push(file);
            // let newFileList = [...fileList];
            // setFileList(newFileList);
            // let count = 0;
            // for(let i = 0; i < newFileList.length; i++){
            //     console.log("append file " + i);
            //     formData.append(`report_${count++}`, newFileList[i]);
            // }
            // if(timeoutConstant){
            //     clearTimeout(timeoutConstant);
            //     setTimeoutConstant(null);
            // }
            // let timeoutIns = setTimeout(() => {
            //     console.log("setTimeout: " + fileList.length);
            // }, 1000);
            // setTimeoutConstant(timeoutIns);
            const httpConfig = {
                headers: { "content-type": "multipart/form-data" },
                onUploadProgress: (event: any) => {
                    const percent = Math.floor((event.loaded / event.total) * 100);
                    setProgress(percent);
                    if (percent === 100) {
                        setTimeout(() => setProgress(0), 1000);
                    }
                    onProgress({ percent: (event.loaded / event.total) * 100 });
                }
            };
            let templateId: string = (reportTemplateId ? reportTemplateId : '-1');
            formData.append(`report`, file);
            formData.append(`uploadRefId`, file.uid);
            formData.append(`reportTemplateId`, templateId);
            formData.append("userId", "1");
            await axios.post(uploadPath, formData, httpConfig).then((res: any) => {
                // console.log("post: ", res);
                let response: ResponseData = res.data;
                if(response.success){
                    let responseReport: ReportTemplateDTO = response.data
                    generatedList.push(responseReport);
                    let newGeneratedList = [...generatedList];
                    setGeneratedList(newGeneratedList);
                    file.uid = responseReport.templateFile.id;
                    props.onUploaded("success", newGeneratedList, file);
                    console.log("response: ", response)
                    onSuccess(file, res);
                    // onSuccess();
                    notification.success({message: "Upload report '" + file.name + "' Success"});
                }else{
                    props.onUploaded("error", generatedList, file);
                    notification.error({message: "Upload report '" + file.name + "' Fail"});
                    onError();
                }
            }).catch((err: Error) => {
                console.log("err: ", err);
                console.log("err stack: ", err.stack);
                // const error = new Error('Some error');
                onError({event: err});
                props.onUploaded("error", new ResourceFileResponse(false, err.message, null), file);
            });
        },
        beforeUpload: (file: any, bFileList: any) => {
            // console.log("ReportUpload.beforeUpload file: ", bFileList);
            tracker.currentUploading = bFileList;
            return checkExtension(file.name) ? true : Upload.LIST_IGNORE;
        },
        // onChange({ file, fileList }: {file: any, fileList: any}) {
        onChange(res: UploadChangeParam) {
            // console.log("onChange: ", res);
            // console.log("FileUpload.file.status: ", res.file.status);
            switch (res.file.status) {
                case "uploading":
                    // nextState.selectedFileList = [info.file];
                    break;
                case "done":
                    // nextState.selectedFile = info.file;
                    // nextState.selectedFileList = [info.file];
                    // setIsUploaded(true);
                    tracker.uploadedCounting++;
                    console.log("res upload done: ", res, ", tracker: ", tracker);
                    // setFileList(res.fileList);
                    if(tracker.uploadedCounting >= tracker.currentUploading.length){
                        allUploadedCallback();
                    }
                    break;

                case "error":
                    tracker.uploadedCounting++;
                    if(tracker.uploadedCounting >= tracker.currentUploading.length){
                        allUploadedCallback();
                    }
                    // props.onUploaded();
                    break;
            
                default:
                    // error or removed
                    // nextState.selectedFile = null;
                    // nextState.selectedFileList = [];
                    // setFileList(res.fileList);
            }

            // if (res.file.status !== 'uploading') {
            //     console.log("file: ", res.file, "fileList: ", fileList);
            //     setFileList(fileList);
            // }
        },
        onRemove: async (file: any) => {
            let url = `${configs.urlRoot}/report/deleteTemplateFile`;
            let res = await axios.post(url, {templateFileId: file.uid});
            if(res.status == 200){
                let resData: ResponseData = res.data;
                if(resData.success){
                    notification.success({message: "Delete report '" + file.name + "' Success"})
                    
                    return true;
                }else{
                    notification.error({message: "Delete report '" + file.name + "' Fail"})
                    return false;
                }
            }
        },
        defaultFileList: [

        ],
    };

    const checkExtension = (filename: string) => {
        // console.log("checkExtension extall: ", extall, "Filename: ", filename);
        if(filename){
            let extList = filename.split('.');
            if(extList){
                let extOri = extList.pop();
                if(extOri){
                    let ext:string = extOri.toLowerCase();
                    if(extall != null && extall.indexOf(ext) < 0){
                        alert('Extension support : ' + extall);
                        return false;
                    }
                    return true;
                }
            }
        }
        return false;
    }
    const handlePreview = async (file: any) => {
          setPreviewImage(file.url);
          setPreviewVisible(true);
          setPreviewTitle(filename == null ? "" : filename);
      };
    const handleCancel = () => {
        setPreviewVisible(false);
    }
    const previewClicked = async () => {
        if(props.preview && generatedList){
            // console.log(fileList);
            // console.log(generatedList);
            console.log("previewClicked");
            const previewFn = props.preview;
            previewFn("success", "");
            // let sortGeneratedList: ReportTemplateDTO[] = fileList.map((data) => {
            //     for(let i = 0; i < generatedList.length; i++){
            //         if(data.uid == generatedList[i].uploadRefId ){
            //             return generatedList[i];
            //         }
            //     }
            //     return generatedList[0];
            // });
            // let previewPdfList: string[] = sortGeneratedList.map((data, index) => {return data.previewReport})
            // const mergePath = `${configs.urlRoot}/report/mergePdf`;
            // await axios.post(mergePath, previewPdfList).then((res: any) => {
            //     console.log("post: ", res);
            //     let response: ResponseData = res.data;
            //     if(response.success){
            //         // props.onUploaded("success", res.data, file);
            //         previewFn("success", response.data);
            //     }else{
            //         // props.onUploaded("error", res.data, file);
            //     }
            // }).catch((err: Error) => {
            //     console.log("err: ", err);
            //     console.log("err stack: ", err.stack);
            //     // const error = new Error('Some error');
            //     // onError({event: err});
            //     // props.onUploaded("error", new ResourceFileResponse(false, err.message, null), file);
            // });
        }
        // console.log("generatedList: ", generatedList);
        // let formData = new FormData();
        // for(let i = 0; i < generatedList.length; i++){
        //     // console.log(`append report_${i}: `, generatedList[i]);
        //     formData.append(`report_${i}`, generatedList[i].previewReport);
        // }
        // // formData.append(`reports_${0}`, "test");
        // formData.append("userId", "1");
        // const httpConfig = {
        //     // headers: { "content-type": "multipart/form-data" },
        //     // onUploadProgress: (event: any) => {
        //     // }
        // };
        // // for(let key in formData.keys()){
        // //     console.log("formData key: ", key);
        // // }
        // const listUploadPath = `${configs.urlRoot}/report/mergePdf`;
        // await axios.post(listUploadPath, formData, httpConfig).then((res: any) => {
        //     console.log("post: ", res);
        //     let response: ResponseData = res.data;
        //     if(response.success){
        //         // props.onUploaded("success", res.data, file);
        //     }else{
        //         // props.onUploaded("error", res.data, file);
        //     }
        // }).catch((err: Error) => {
        //     console.log("err: ", err);
        //     console.log("err stack: ", err.stack);
        //     // const error = new Error('Some error');
        //     // onError({event: err});
        //     // props.onUploaded("error", new ResourceFileResponse(false, err.message, null), file);
        // });
    }
    const clearClicked = async () => {
        let url = `${configs.urlRoot}/report/deleteTemplateFile`;
        console.log("clear '" + fileList.length + "' files")
        for(let i = 0; i < fileList.length; i++){
            let file = fileList[i];
            console.log("remove " + i, file);
            let res = await axios.post(url, {templateFileId: file.uid});
            if(res.status == 200){
                let resData: ResponseData = res.data;
                if(resData.success){
                    notification.success({message: "Delete report '" + file.name + "' Success"})
                    if(props.clearReport){
                        props.clearReport();
                    }
                }else{
                    notification.error({message: "Delete report '" + file.name + "' Fail"})
                }
            }
        }
        setFileList([]);
    }
    const moveRow = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            console.log("moveRow");
            const dragRow = fileList[dragIndex];
            let newFileList: any[] = update(fileList, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRow],
                ],
            });
            setFileList(newFileList);
            console.log("newFileList: ", newFileList);
            sortNewList(newFileList);
        }, [fileList],
      );
    const renderDefaulChildren = () => {
        if(fileList.length > 0){
            return (
                <>
                    <Button style={{marginLeft: 5}} type="default" icon={<DeleteOutlined />} onClick={clearClicked}>Clear</Button>
                    <Button style={{marginLeft: 5}} type="primary" icon={<FilePdfOutlined />} onClick={() => {previewClicked()}}>Preview</Button>
                </>
            );
        }
    }
    return (
        <DndProvider backend={HTML5Backend}>
            <Upload 
                {...uploadProps} 
                {...props.uploadProps} 
                listType={"text"} 
                fileList={fileList} 
                multiple
                itemRender={(originNode, file, currFileList) => (
                    <DragableUploadListItem
                        originNode={originNode}
                        file={file}
                        fileList={currFileList}
                        moveRow={moveRow}
                    />
                )}
            >
                <Button icon={<FileAddOutlined />}>เลือก jrxml</Button>
            </Upload>
            {renderDefaulChildren()}
        </DndProvider>
    );
};

export default ReportUpload;
