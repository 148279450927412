import React, { useEffect, useState } from "react";
import configs from "../../../constants/config";

const TestReport = (props: any) => {
    const id = 18;
    const frameUrl = `${configs.urlRoot}/report/docx/readPDF/Preview/${id}.pdf`
    const [previewSource, setPreviewSource] = useState<string[]>([]);

    useEffect(() => {
        let urlList = [
            `${configs.urlRoot}/report/docx/readPDF/Preview/${id}.pdf?r=${Math.random()}`
            ,`${configs.urlRoot}/report/docx/readPDF/Preview/${id}.pdf?r=${Math.random()}`
            ,`${configs.urlRoot}/report/docx/readPDF/Preview/${id}.pdf?r=${Math.random()}`
            ,`${configs.urlRoot}/report/docx/readPDF/Preview/${id}.pdf?r=${Math.random()}`
            ,`${configs.urlRoot}/report/docx/readPDF/Preview/${id}.pdf?r=${Math.random()}`
            ,`${configs.urlRoot}/report/docx/readPDF/Preview/${id}.pdf?r=${Math.random()}`
            ,`${configs.urlRoot}/report/docx/readPDF/Preview/${id}.pdf?r=${Math.random()}`
            ,`${configs.urlRoot}/report/docx/readPDF/Preview/${id}.pdf?r=${Math.random()}`
            ,`${configs.urlRoot}/report/docx/readPDF/Preview/${id}.pdf?r=${Math.random()}`
            ,`${configs.urlRoot}/report/docx/readPDF/Preview/${id}.pdf?r=${Math.random()}`
        ]
        setPreviewSource(urlList);
    }, [])

    const renderPDFList = () => {
        let result: any[] = previewSource.map((val) => {
            return (
                <iframe title={"ตัวอย่างรายงาน"} name="preview_frame" style={{width: "95%", minHeight: '600px', height: '100%'}} src={val}></iframe>
            )
        });
        
        return result;
    }

    return (
        <div>
            Test Report
            {/* {renderPDFList()} */}

            <iframe title={"ตัวอย่างรายงาน"} name="preview_frame" style={{width: "95%", minHeight: '600px', height: '100%'}} src={`${frameUrl}?r=${Math.random()}`}></iframe>
            <iframe title={"ตัวอย่างรายงาน"} name="preview_frame" style={{width: "95%", minHeight: '600px', height: '100%'}} src={`${frameUrl}?r=${Math.random()}`}></iframe>
            <iframe title={"ตัวอย่างรายงาน"} name="preview_frame" style={{width: "95%", minHeight: '600px', height: '100%'}} src={`${frameUrl}?r=${Math.random()}`}></iframe>
            <iframe title={"ตัวอย่างรายงาน"} name="preview_frame" style={{width: "95%", minHeight: '600px', height: '100%'}} src={`${frameUrl}?r=${Math.random()}`}></iframe>
            <iframe title={"ตัวอย่างรายงาน"} name="preview_frame" style={{width: "95%", minHeight: '600px', height: '100%'}} src={`${frameUrl}?r=${Math.random()}`}></iframe>
            <iframe title={"ตัวอย่างรายงาน"} name="preview_frame" style={{width: "95%", minHeight: '600px', height: '100%'}} src={`${frameUrl}?r=${Math.random()}`}></iframe>
            <iframe title={"ตัวอย่างรายงาน"} name="preview_frame" style={{width: "95%", minHeight: '600px', height: '100%'}} src={`${frameUrl}?r=${Math.random()}`}></iframe>
            <iframe title={"ตัวอย่างรายงาน"} name="preview_frame" style={{width: "95%", minHeight: '600px', height: '100%'}} src={`${frameUrl}?r=${Math.random()}`}></iframe>
            <iframe title={"ตัวอย่างรายงาน"} name="preview_frame" style={{width: "95%", minHeight: '600px', height: '100%'}} src={`${frameUrl}?r=${Math.random()}`}></iframe>
            <iframe title={"ตัวอย่างรายงาน"} name="preview_frame" style={{width: "95%", minHeight: '600px', height: '100%'}} src={`${frameUrl}?r=${Math.random()}`}></iframe>
        </div>
    )
}
export default TestReport;