import axios from "axios";
import config from "../constants/config";
import { Application } from "../models/MasterModels";
import ResponseData from "../models/ResponseData";
import { CreateReportTemplateModel, ReportTemplate, ReportTemplateModel } from "../views/report/UploadTemplateModel";


const reportUrl = `${config.urlRoot}/report`;

export const getTemplateByAppIdList = async (appIdList: any[]) => {
    let url = `${reportUrl}/getTemplateByAppIdList`;
    let res = await axios.post(url, {applicationId: appIdList});
    let result: ReportTemplate[] = [];
    // console.log("status: ", res.status);
    if(res.status == 200){
        let resData: ResponseData = res.data;
        result = resData.data;
        // console.log("resData: ", resData);
    }
    return result == null ? [] : result;
}

export const createReportTemplateService = async (templateData: CreateReportTemplateModel) => {
    let url = `${reportUrl}/createReportTemplate`;
    let res = await axios.post(url, templateData);
    // let result: ReportTemplate[] = [];
    // console.log("status: ", res.status);
    if(res.status == 200){
        let resData: ResponseData = res.data;
        // result = resData.data;
        // console.log("resData: ", resData);
        return resData;
    }
    return null;
}

export const updateReportTemplateService = async (templateData: ReportTemplateModel) => {
    let url = `${reportUrl}/updateReportTemplate`;
    let res = await axios.post(url, templateData);
    // let result: ReportTemplate[] = [];
    // console.log("status: ", res.status);
    if(res.status == 200){
        let resData: ResponseData = res.data;
        // result = resData.data;
        // console.log("resData: ", resData);
        return resData;
    }
    return null;
}

export const deleteReportTemplateService = async (reportTemplateId: number) => {
    let url = `${reportUrl}/deleteReportTemplate`;
    let res = await axios.post(url, {reportTemplateId: reportTemplateId});
    // let result: ReportTemplate[] = [];
    console.log("status: ", res.status);
    if(res.status == 200){
        let resData: ResponseData = res.data;
        // result = resData.data;
        // console.log("resData: ", resData);
        return resData;
    }
    return null;
}

export const getTemplateById = async (templateId: any) => {
    let url = `${reportUrl}/getTemplateById/${templateId}`;
    let res = await axios.get(url);
    let result: CreateReportTemplateModel | null = null;
    // console.log("status: ", res.status);
    if(res.status == 200){
        let resData: ResponseData = res.data;
        result = resData.data;
        // console.log("resData: ", resData);
    }
    return result;
}

export const getDocxTemplateById = async (templateId: any) => {
    let url = `${reportUrl}/docx/getTemplateById/${templateId}`;
    let res = await axios.get(url);
    let result: CreateReportTemplateModel | null = null;
    // console.log("status: ", res.status);
    if(res.status == 200){
        let resData: ResponseData = res.data;
        result = resData.data;
        // console.log("resData: ", resData);
    }
    return result;
}

export const changeStatusService = async (newStatus: string, templateId: any) => {
    let url = `${reportUrl}/changeStatus`;
    let res = await axios.post(url, {status: newStatus, reportTemplateId: templateId});
    // let result: CreateReportTemplateModel | null = null;
    // console.log("status: ", res.status);
    // if(res.status == 200){
    //     let resData: ResponseData = res.data;
    //     result = resData.data;
    //     console.log("resData: ", resData);
    // }
    return res;
}

export const saveHtmlTemplate = async (params: any) => {
    let url = `${reportUrl}/saveHtmlTemplate`;
    let res = await axios.post(url, params);
    // let result: CreateReportTemplateModel | null = null;
    // console.log("status: ", res.status);
    // if(res.status == 200){
    //     let resData: ResponseData = res.data;
    //     result = resData.data;
    //     console.log("resData: ", resData);
    // }
    return res;
}

export const getTemplateHtmlByTemplateId = async (templateId: any) => {
    let url = `${reportUrl}/getTemplateHtmlByTemplateId/${templateId}`;
    let res = await axios.get(url);
    let result: ReportTemplateModel | null = null;
    // console.log("status: ", res.status);
    if(res.status == 200){
        let resData: ResponseData = res.data;
        result = resData.data;
        // console.log("resData: ", resData);
    }
    return result;
}