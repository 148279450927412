import React, { useEffect, useState } from "react";
import parse from 'html-react-parser';

const CheckTemplate = () => {
    const [currentHtmlElement, setCurrentHtmlElement] = useState();
    useEffect(() => {
        let htmlElement = parse("<div<></div>")
        setCurrentHtmlElement(htmlElement);
    }, [])
    return(
        <div 
            id="pdfPreview" 
            className="mt4"
            style={{
                backgroundColor: '#ffffff',
                width: '210mm',
                height: '297mm',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: '10mm',
                overflowY: 'auto'
            }}
        >
            {currentHtmlElement}
        </div>
    )
}
export default CheckTemplate;