import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useRoutes,
} from "react-router-dom";
import Home from "./views/Home";
import LoginForm from "./views/LoginForm";
import ModernAppLayout from "./layouts/ModernAppLayout";
import UploadTemplate from "./views/report/UploadTemplate";
import ReportList from "./views/report/ReportList";
import TemplateList from "./views/template/list/TemplateList";
import CreateTemplate from "./views/template/create/CreateTemplate";
import Html2Pdf from "./views/template/export/Html2Pdf";
import CheckTemplate from "./views/template/create/CheckTemplate";
import UploadDocxTemplate from "./views/template/docx/UploadDocxTemplate";
import TestReport from "./views/template/docx/TestReport";
// import "./app.css"
// import AppRoute from "./views/AppRoute";

const App: React.FC = () => {
  const mainRoutes = {
    path: '/',
    element: <Navigate to='/app' />,
    // children: [
    //   {path: '*', element: <Navigate to='/404' />},
    //   // {path: '/', element: <MainView />},
    //   // {path: '404', element: <PageNotFoundView />},
    //   // {path: 'account', element: <Navigate to='/account/list' />},
    // ],
  };

  const accountRoutes = {
    path: 'app',
    element: <ModernAppLayout />,
    children: [
      {
        path: "",
        element: <ReportList />,
      },
      {
        path: "TemplateReport",
        // element: <UploadTemplate />,
        children: [
          {
            path: "",
            element: <ReportList />,
          },
          {
            path: "list",
            element: <ReportList />,
          },
          {
            path: "upload",
            element: <UploadTemplate />,
          },
          {
            path: "upload/:id",
            element: <UploadTemplate />,
          },
        ]
      },
      {
        path: "Template",
        // element: <UploadTemplate />,
        children: [
          {
            path: "",
            element: <ReportList />,
          },
          {
            path: "list",
            element: <ReportList />,
          },
          {
            path: "create",
            element: <CreateTemplate />,
          },
          {
            path: "create/:id",
            element: <CreateTemplate />,
          },
          {
            path: "export",
            element: <Html2Pdf />,
          },
          {
            path: "check",
            element: <CheckTemplate />,
          },
          {
            path: "docx/:id",
            element: <UploadDocxTemplate />,
          },
          {
            path: "test",
            element: <TestReport />,
          },
        ]
      },
      // { path: "tasks", element: <DashboardTasks /> },
    ],
  };

  const routing = useRoutes([mainRoutes, accountRoutes]);
  return (
    // <Home/>
    // <Router>
    //   <Routes>
    //     <Route path="/" element={<Home/>} />
    //     <Route path="/login" element={<Login/>} />
    //   </Routes>
    // </Router>
    <>{routing}</>
  );
};

export default App;
